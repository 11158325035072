import React, { useEffect } from "react";
import cityLogo from "../Images/logo.webp";
import secureLocalStorage from "react-secure-storage";

function HeaderUser() {
  useEffect(() => {
    secureLocalStorage.setItem("panel", "user");
  }, []);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="translator-holder">
            <div className="logo">
              <img
                src={cityLogo}
                alt="city-logo.png"
                className="img-fluid"
                width="360"
                height="118"
              />
            </div>
          </div>
        </div>
        <div className="fix-white"></div>
      </div>
    </div>
  );
}
export default HeaderUser;
