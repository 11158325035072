import React from "react";
function Footer() {
  return (
    <div className="footer">
      <p>
        © Copyright {new Date().getFullYear()}. All Rights Reserved.{" "}
        {process.env.REACT_APP_API_SITE_TITLE}.
      </p>
    </div>
  );
}
export default Footer;
