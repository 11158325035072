import React from "react";
function HeaderLower() {
  return (
    <></>
    // <div className="header_lower">
    //   Ashley Furniture and Elite Rewards are proud supporters of <br />
    //   Hope to Dream.
    // </div>
  );
}
export default HeaderLower;
